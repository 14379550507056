<template>
  <div class="mt-4 sales-tax-settings w-100 payments-block">
    <div class="card payments-block__card">
      <div class="card-body">
        <h3>Active Zipcodes</h3>
        <div class="d-grid place-center p-4" v-if="fetchingZipcodes">
          <span class="spinner spinner-danger"></span>
        </div>
        <ul class="list-group list-group-flush" v-else>
          <li
            class="list-group-item"
            v-for="zipcode in zipcodes"
            :key="zipcode.settingId"
          >
            <active-zipcode-item :setting="zipcode" />
          </li>
          <li class="list-group-item">
            <active-zipcode-item />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_ACTIVE_ZIPCODES } from "@/core/services/store/settings-us.module.js";
import ActiveZipcodeItem from "@/view/pages/settings/us/ActiveZipcodeItem";
export default {
  name: "ActiveZipcodes",
  components: {
    ActiveZipcodeItem
  },
  mounted() {
    this.$store.dispatch(GET_ACTIVE_ZIPCODES);
  },
  computed: {
    ...mapState({
      zipcodes: ({ settingsUs }) => settingsUs.zipcodes,
      fetchingZipcodes: ({ settingsUs }) => settingsUs.fetchingZipcodes
    })
  }
};
</script>

<style lang="scss" scoped></style>
